import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../Header/AHeader';
import { useAuth } from '../../context/AuthContext';
import { API_URL } from '../../context/AuthContext';

import '../../assets/css/Dashboard.css';
import '../../assets/css/ALogin.css';
import '../../assets/css/errors.css';
import '../../assets/css/settings.css';
import '../../assets/css/input.css';
import '../../assets/css/forms.css';

const SubjectForm = () => {
    const { user } = useAuth(); // استخدم السياق
    const [subject, setSubject] = useState('');
    const [department, setDepartment] = useState('');
    const [departments, setDepartments] = useState([]); // حالة جديدة للأقسام
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // استخدام useEffect لجلب الأقسام عند تحميل المكون
    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/auth/get-departments`);
                setDepartments(response.data); // تعيين الأقسام المجلوبة
            } catch (err) {
                setError('حدث خطأ أثناء جلب الأقسام.');
            }
        };

        fetchDepartments();
    }, []); // مصفوفة فارغة تعني أن هذا التأثير سيعمل مرة واحدة عند تحميل المكون

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await axios.post(`${API_URL}/api/auth/add-subject`, {
                subject,
                department,
            });

            setSuccess(response.data); // عرض رسالة النجاح
            setSubject(''); // إعادة تعيين الحقول
            setDepartment('');
        } catch (err) {
            if (err.response) {
                setError(`خطأ ${err.response.status}: ${err.response.data}`);
            } else {
                setError('حدث خطأ أثناء إعداد الطلب.');
            }
        }
    };

    return (
        <div className="global">
            <div className="dashboardContent">
                <Header />
                <div className='mainContent'>
                    <div className="welcomeWrapper">
                        <div className="welcome">
                            <h1 className="welcomeUser">
                                <span>مرحبا</span> {user.fname_ar} {user.lname_ar},
                            </h1>
                        </div>
                    </div>

                    {/* Display error message if exists */}
                    {error && <div className="error" style={{ marginBottom: '20px' }}>{error}</div>}
                    {success && <div className="success" style={{ marginBottom: '20px' }}>{success}</div>}

                    <form id="formSubject" onSubmit={handleSubmit}>
                        <div className="formStyle">
                            <div className="widgetsContainer">
                                <div className="widget">
                                    <div className="widgetHeader light">
                                        <h2 className="title">إضافة مادة جديدة</h2>
                                    </div>
                                    <div className="widgetCont">
                                        <div className="col-md-12">
                                            <div className="form-group col-md-6">
                                                <label>اسم المادة</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="أدخل اسم المادة"
                                                    required
                                                    value={subject}
                                                    onChange={(e) => setSubject(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>القسم</label>
                                                <select
                                                    className="form-control"
                                                    required
                                                    value={department}
                                                    onChange={(e) => setDepartment(e.target.value)}
                                                >
                                                    <option value="">اختر القسم</option>
                                                    {departments.map((dept) => (
                                                        <option key={dept.id} value={dept.id}>
                                                            {dept.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary w-100"
                                                >
                                                    إضافة
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SubjectForm;