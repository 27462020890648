import React, { useState } from 'react';
import axios from 'axios';
import Header from '../../Header/AHeader';
import { useAuth } from '../../context/AuthContext';
import { API_URL } from '../../context/AuthContext';

import '../../assets/css/Login.css';
import '../../assets/css/errors.css';
import '../../assets/css/settings.css';
import '../../assets/css/global.css';
import '../../assets/css/input.css';
import '../../assets/css/forms.css';

const AddDepartment = () => {
    const { user } = useAuth(); // استخدم السياق
    const [newDepartment, setNewDepartment] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await axios.post(`${API_URL}/api/auth/add-department`, {
                department: newDepartment,
            });

            setSuccess(response.data);
            setNewDepartment(''); // Clear the input field
        } catch (err) {
            if (err.response) {
                setError(`خطأ ${err.response.status}: ${err.response.data}`);
            } else {
                setError('حدث خطأ أثناء إعداد الطلب.');
            }
        }
    };

    return (
        <div className="global">
            <div className="dashboardContent">
                <Header />
                <div className='mainContent'>
                    <div className="welcomeWrapper">
                        <div className="welcome">
                            <h1 className="welcomeUser">
                                <span>مرحبا</span> {user.fname_ar} {user.lname_ar},
                            </h1>
                        </div>
                    </div>

                    <div className="bannerWrapper"></div>

                    <div className="modals" id="loadingDiv"></div>
                    <div className="formStyle">
                    <div className="widgetsContainer">
                         <div className="widget">
                             <div className="widgetHeader light">
                                <h2 className="title">تتبع النقط</h2>
                            </div>
                    <div className='widgetCont'>
<div className="main">
<div className="leftSide">
    <div className="inner">
        <form id="formDepartment" onSubmit={handleSubmit}>
            <div className="formLogin">
                <div className="formLoginStyle">
                    {error && <div className="error">{error}</div>} {/* رسالة الخطأ */}
                    {success && <div className="success">{success}</div>} {/* رسالة النجاح */}
                    <div className="item">
                        <input
                            id="NewDepartment"
                            name="newDepartment"
                            placeholder="اسم القسم الجديد"
                            required
                            type="text"
                            value={newDepartment}
                            onChange={(e) => setNewDepartment(e.target.value)}
                        />
                    </div>
                    <div className="item">
                        <button id="btnSubmit" className="submitBtn" type="submit">
                            إضافة قسم
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</div>
                    </div>
                            </div>
                    </div>
                  
                </div>

            </div>
        </div>        </div>

    );
};

export default AddDepartment;