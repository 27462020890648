import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import Header from '../../Header/AHeader';
import '../../assets/css/Dashboard.css';
import '../../assets/css/Login.css';
import '../../assets/css/errors.css';
import '../../assets/css/settings.css';
import '../../assets/css/input.css';
import '../../assets/css/forms.css';
import { API_URL } from '../../context/AuthContext';

const ManageSubjects = () => {
    const { user } = useAuth();
    const [subjects, setSubjects] = useState([]);
    const [coefficients, setCoefficients] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true); // حالة التحميل

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await fetch(`${API_URL}/api/auth/subjects`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log('Fetched subjects:', data);
                setSubjects(Array.isArray(data) ? data : []);
            } catch (error) {
                console.error('Error fetching subjects:', error);
                setErrorMessage('حدث خطأ أثناء جلب المواد.');
                setSubjects([]);
            } finally {
                setLoading(false); // تعيين حالة التحميل إلى false بغض النظر عن النجاح أو الفشل
            }
        };

        fetchSubjects();
    }, []);

    const handleCoefficientChange = (id, value) => {
        setCoefficients({ ...coefficients, [id]: value });
    };

    const handleSaveCoefficients = async () => {
        const subjectsWithCoefficients = subjects.map(subject => ({
            id: subject.id,
            coefficient: parseFloat(coefficients[subject.id]) || 0 // استخدم 0 إذا لم يتم إدخال قيمة
        }));

        try {
            const response = await fetch(`${API_URL}/api/auth/subjects/coefficient`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ subjects: subjectsWithCoefficients }),
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            alert('تم تحديث المعاملات بنجاح.');
            setErrorMessage(''); // مسح رسالة الخطأ عند النجاح
        } catch (error) {
            console.error('Error saving coefficients:', error);
            alert('حدث خطأ أثناء تحديث المعاملات.');
            setErrorMessage('حدث خطأ أثناء تحديث المعاملات.'); // تخزين رسالة الخطأ
        }
    };

    const handleDeleteSubject = async (subjectId) => {
        try {
            const response = await fetch(`${API_URL}/api/auth/subjects/${subjectId}`, {
                method: 'DELETE',
            });
    
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
    
            // قم بتحديث الحالة لإزالة المادة المحذوفة من العرض
            setSubjects(prevSubjects => prevSubjects.filter(subject => subject.id !== subjectId));
            alert('تم حذف المادة بنجاح.');
        } catch (error) {
            console.error('Error deleting subject:', error);
            alert('حدث خطأ أثناء حذف المادة.');
        }
    };
    

    // التحقق من صحة المعاملات
    const areCoefficientsValid = subjects.every(subject => 
        !isNaN(parseFloat(coefficients[subject.id])) // التأكد من أن كل معامل هو رقم صالح
    );

    return (
        <div>
            <div className="global">
                <div className="dashboardContent">
                    <Header />
                    <div className='mainContent'>
                        <div className="welcomeWrapper">
                            <div className="welcome">
                                <h1 className="welcomeUser">
                                    <span>مرحبا</span> {user.fname_ar} {user.lname_ar},
                                </h1>
                            </div>
                        </div>
                        <div className="formStyle">
                            <div className="widgetsContainer">
                                <div className="widget">
                                    <div className="widgetHeader light">
                                        <h2 className="title">إدارة المواد</h2>
                                    </div>
                                    <div className="widgetCont">
                                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                                        {loading ? (
                                            <p>Loading subjects...</p> // رسالة التحميل
                                        ) : (
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>اسم المادة</th>
                                                                <th>المعامل</th>
                                                                <th>الإجراءات</th> {/* عمود الإجراءات */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {subjects.length > 0 ? (
                                                                subjects.map(subject => (
                                                                    <tr key={subject.id}>
                                                                        <td>{subject.subject_name}</td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                step="0.01"
                                                                                value={coefficients[subject.id]} // استخدم القيمة من الحالة
                                                                                onChange={e => handleCoefficientChange(subject.id, e.target.value)}
                                                                                className="coefficient-input"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                className="btn btn-danger"
                                                                                onClick={() => handleDeleteSubject(subject.id)}
                                                                            >
                                                                                حذف
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="3">لا توجد مواد متاحة.</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary w-100"
                                                        onClick={handleSaveCoefficients}
                                                        // disabled={!areCoefficientsValid} // تعطيل الزر إذا كانت أي معامل غير صالحة
                                                    >
                                                        حفظ المعاملات
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageSubjects;