import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Header from '../../Header/AHeader';
import { useAuth } from '../../context/AuthContext';
import { API_URL } from '../../context/AuthContext';

import '../../assets/css/Dashboard.css';
import '../../assets/css/ALogin.css';
import '../../assets/css/errors.css';
import '../../assets/css/settings.css';
import '../../assets/css/input.css';
import '../../assets/css/forms.css';

const ASearchStudent = () => {
    const { user } = useAuth();
    const [studentName, setStudentName] = useState('');
    const [studentId, setStudentId] = useState('');
    const [selectedYear, setSelectedYear] = useState('2024');
    const [selectedSession, setSelectedSession] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [departments, setDepartments] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);

    // جلب الأقسام من الخادم
    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/auth/get-departments`);
                setDepartments(response.data);
            } catch (err) {
                setError('حدث خطأ أثناء جلب الأقسام.');
            }
        };

        fetchDepartments();
    }, []);

    const handleSearch = async (e) => {
        e.preventDefault();
        setError('');
    
        const searchTerm = studentName || studentId;
    
        if (!searchTerm) {
            setError('الرجاء إدخال اسم الطالب أو رقم الطالب للبحث');
            return;
        }
    
        try {
            const response = await axios.get(`${API_URL}/api/auth/search-student/${searchTerm}`);
            setSearchResults(response.data);
        } catch (err) {
            setError('حدث خطأ أثناء البحث.');
        }
    };

    const openModal = (student) => {
        setSelectedStudent(student);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedStudent(null);
    };

    const handleSaveChanges = async () => {
        if (!selectedStudent) return;
    
        try {
            await axios.put(`${API_URL}/api/auth/update-student/${selectedStudent.student_id}`, selectedStudent);
            
            // تحديث عدد الطلاب في القسم بعد تعديل الطالب
            await axios.put(`${API_URL}/api/auth/update-department-student-count/${selectedStudent.department_id}`, {
                action: 'increment'
            });
    
            alert('تم تحديث المعلومات بنجاح!');
            closeModal();
        } catch (err) {
            console.error('Error updating student:', err);
            setError('حدث خطأ أثناء تحديث المعلومات.');
        }
    };

    const checkDepartmentCapacity = async (departmentId) => {
        try {
            const response = await axios.get(`${API_URL}/api/auth/check-department-capacity/${departmentId}`);
            return response.data.available; // إرجاع true إذا كان هناك مساحة
        } catch (err) {
            console.error('Error checking department capacity:', err);
            return false; // في حال حدوث خطأ
        }
    };

    
    const handleAddStudent = async (newStudent) => {
        const hasCapacity = await checkDepartmentCapacity(newStudent.department_id);
        if (!hasCapacity) {
            alert('لا يمكن إضافة الطالب. القسم ممتلئ.');
            return;
        }
    
        // متابعة عملية إضافة الطالب
        try {
            await axios.post(`${API_URL}/api/auth/add-student`, newStudent);
            // بعد الإضافة، قم بتحديث عدد الطلاب في القسم
            await axios.put(`${API_URL}/api/auth/update-department-student-count/${newStudent.department_id}`, {
                action: 'increment'
            });
            alert('تم إضافة الطالب بنجاح!');
        } catch (err) {
            console.error('Error adding student:', err);
        }
    };
    const addStudent = async (newStudent) => {
        // أولاً، تحقق من سعة القسم
        const hasCapacity = await checkDepartmentCapacity(newStudent.department_id);
        if (!hasCapacity) {
            alert('لا يمكن إضافة الطالب. القسم ممتلئ.');
            return;
        }
    
        // الآن، أضف الطالب إلى قاعدة البيانات
        try {
            await axios.post(`${API_URL}/api/auth/add-student`, newStudent);
            // بعد الإضافة، قم بتحديث عدد الطلاب في القسم
            await axios.put(`${API_URL}/api/auth/update-department-student-count/${newStudent.department_id}`, {
                action: 'increment'
            });
            alert('تم إضافة الطالب بنجاح!');
        } catch (err) {
            console.error('Error adding student:', err);
            alert('حدث خطأ أثناء إضافة الطالب.');
        }
    };

    

    return (
        <div className="global">
            <div className="dashboardContent">
                <Header />
                <div className='mainContent'>
                    <div className="welcomeWrapper">
                        <div className="welcome">
                            <h1 className="welcomeUser">
                                <span>مرحبا</span> {user.fname_ar} {user.lname_ar},
                            </h1>
                        </div>
                    </div>

                    {error && <div className="error" style={{ marginBottom: '20px' }}>{error}</div>}

                    <form id="formUser" onSubmit={handleSearch}>
                        <div className="formStyle">
                            <div className="widgetsContainer">
                                <div className="widget">
                                    <div className="widgetHeader light">
                                        <h2 className="title">بحث عن تلميذ</h2>
                                    </div>
                                    <div className="widgetCont">
                                        <div className="col-md-12">
                                            <div className="form-group col-md-3">
                                                <label>اسم الطالب</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="أدخل اسم الطالب"
                                                    value={studentName}
                                                    onChange={(e) => setStudentName(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label>رقم الطالب</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="أدخل رقم الطالب"
                                                    value={studentId}
                                                    onChange={(e) => setStudentId(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label>القسم</label>
                                                <select
                                                    className="form-control"
                                                    value={selectedDepartment}
                                                    onChange={(e) => setSelectedDepartment(e.target.value)}
                                                >
                                                    <option value="">اختر القسم</option>
                                                    {departments.map((dept) => (
                                                        <option key={dept.id} value={dept.id}>
                                                            {dept.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label className="label">.</label>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary w-100"
                                                >
                                                    البحث
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {searchResults.length > 0 && (
                                        <div className="table-responsive">
                                            <table className="table table-bordered mt-4">
                                                <thead>
                                                    <tr>
                                                        <th>رقم الطالب</th>
                                                        <th>الاسم الأول</th>
                                                        <th>الاسم الأخير</th>
                                                        <th>السنة الدراسية</th>
                                                        <th>الفصل الدراسي</th>
                                                        <th>القسم</th>
                                                        <th>الإجراءات</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {searchResults.map((student) => (
                                                        <tr key={student.student_id}>
                                                            <td>{student.student_id}</td>
                                                            <td>{student.fname_ar}</td>
                                                            <td>{student.lname_ar}</td>
                                                            <td>{selectedYear}</td>
                                                            <td>{selectedSession === '1' ? 'الترم الأول' : 'الترم الثاني'}</td>
                                                            <td>{student.department_name}</td>
                                                            <td>
                                                                <button
                                                                 type="button"
                                                        className="btn btn-primary w-100"
                                                                    onClick={() => openModal(student)}
                                                                >
                                                                    تعديل
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>

                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        contentLabel="تعديل معلومات الطالب"
                        ariaHideApp={false}
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                            },
                            content: {
                                width: '80%',
                                height: '80%',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                overflow: 'auto',
                            },
                        }}
                    >
                        <h2>تعديل معلومات الطالب</h2>
                        {selectedStudent && (
                            <div>
                                <div className="form-group">
                                    <label>اسم الطالب (الأول)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectedStudent.fname_ar}
                                        onChange={(e) => setSelectedStudent({ ...selectedStudent, fname_ar: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>اسم الطالب (الأخير)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectedStudent.lname_ar}
                                        onChange={(e) => setSelectedStudent({ ...selectedStudent, lname_ar: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>اسم الطالب (الأول) بالفرنسية</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectedStudent.fname_fr}
                                        onChange={(e) => setSelectedStudent({ ...selectedStudent, fname_fr: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>اسم الطالب (الأخير) بالفرنسية</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectedStudent.lname_fr}
                                        onChange={(e) => setSelectedStudent({ ...selectedStudent, lname_fr: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>كلمة المرور</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        value={selectedStudent.password}
                                        onChange={(e) => setSelectedStudent({ ...selectedStudent, password: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>حالة الطالب</label>
                                    <select
                                        className="form-control"
                                        value={selectedStudent.status}
                                        onChange={(e) => setSelectedStudent({ ...selectedStudent, status: e.target.value })}
                                    >
                                        <option value="active">نشط</option>
                                        <option value="inactive">غير نشط</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>المستوى الدراسي</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectedStudent.niveau_scolaire}
                                        onChange={(e) => setSelectedStudent({ ...selectedStudent, niveau_scolaire: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>اسم المدرسة</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectedStudent.school_name}
                                        onChange={(e) => setSelectedStudent({ ...selectedStudent, school_name: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>رقم القسم</label>
                                    <select
                                        className="form-control"
                                        value={selectedStudent.department_id}
                                        onChange={(e) => setSelectedStudent({ ...selectedStudent, department_id: e.target.value })}
                                    >
                                        <option value="">اختر القسم</option>
                                        {departments.map((dept) => (
                                            <option key={dept.id} value={dept.id}>
                                                {dept.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <button className="btn btn-success" onClick={handleSaveChanges}>
                                    حفظ التغييرات
                                </button>
                                <button className="btn btn-danger" onClick={closeModal}>
                                    إغلاق
                                </button>
                            </div>
                        )}
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default ASearchStudent;