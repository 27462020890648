import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import Header from '../../Header/AHeader';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { API_URL } from '../../context/AuthContext';
import '../../assets/css/ADashboard.css';
import '../../assets/css/Dashboard.css';
import '../../assets/css/Login.css';
import '../../assets/css/errors.css';
import '../../assets/css/settings.css';
import '../../assets/css/input.css';
import '../../assets/css/forms.css';

const ADashboard = () => {
    const { user } = useAuth();

    const [studentCount, setStudentCount] = useState(0);
    const [departmentCount, setDepartmentCount] = useState(0);
    const [subjectCount, setSubjectCount] = useState(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchStatistics = async () => {
            try {
                const response = await axios.get('http://localhost:2083/api/auth/statistics');
                setStudentCount(response.data.studentCount);
                setDepartmentCount(response.data.departmentCount);
                setSubjectCount(response.data.subjectCount);

                // تحديث بيانات الرسم البياني
                setData([
                    { name: 'عدد التلاميذ', value: response.data.studentCount },
                    { name: 'عدد الأقسام', value: response.data.departmentCount },
                    { name: 'عدد المواد', value: response.data.subjectCount }
                ]);
            } catch (error) {
                console.error('Error fetching statistics:', error);
            }
        };

        fetchStatistics();
    }, []);

    // Check if user is defined
    if (!user) {
        return <div>Loading...</div>; // or a redirection to login
    }

    return (
        <div className="global">
            <div className="dashboardContent">
                <Header />
                <div className='mainContent'>
                    <div className="welcomeWrapper">
                        <div className="welcome">
                            <h1 className="welcomeUser">
                                <span>مرحبا</span> {user.fname_ar} {user.lname_ar},
                            </h1>
                        </div>
                    </div>

                    <div className="dashboard">
                        <h1>لوحة المعلومات</h1>
                        <div className="info-row">
                            <div className="info-box red">
                                <div className="icon">
                                </div>
                                <div className="content">
                                    <div className="text">عدد التلاميذ</div>
                                    <div className="number">{studentCount}</div>
                                </div>
                            </div>
                            <div className="info-box blue">
                                <div className="icon">
                                </div>
                                <div className="content">
                                    <div className="text">عدد الأقسام</div>
                                    <div className="number">{departmentCount}</div>
                                </div>
                            </div>
                            <div className="info-box green">
                                <div className="icon">
                                </div>
                                <div className="content">
                                    <div className="text">عدد المواد</div>
                                    <div className="number">{subjectCount}</div>
                                </div>
                            </div>
                        </div>

                        {/* إضافة الرسم البياني هنا */}
                        <div className="chart-container">
                            <h2>إحصائيات</h2>
                            <PieChart width={600} height={400}> {/* تكبير حجم الرسم البياني */}
                                <Pie
                                    data={data}
                                    cx={300} // مركز الرسم البياني
                                    cy={200}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                    label
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={['#dc3545', '#007bff', '#28a745'][index]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ADashboard;