import React from 'react';
import ReactDOM from 'react-dom/client'; // استيراد createRoot
import App from './App'; // تأكد من استيراد المكون App الخاص بك

// تحديد عنصر الجذر
const container = document.getElementById('root');

// استخدام createRoot
const root = ReactDOM.createRoot(container);

// قم بتشغيل التطبيق
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
