import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ component: Component }) => {
    const { isAuthenticated } = useAuth(); // استخدم حالة التوثيق

    // إذا كان المستخدم مصدق عليه، قم بإرجاع المكون، وإلا قم بإعادة التوجيه
    return isAuthenticated ? <Component /> : <Navigate to="/moutamadris/Account" />;
};

export default PrivateRoute;