import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { loadScript } from './loadScripts';

const Header = () => {
    const {user} = useAuth();
    useEffect(() => {
        const loadScripts = async () => {
            try {
                await loadScript('https://massarservice.men.gov.ma/moutamadris/ScriptsIntegration/assets/js/lib/jquery-1.12.4.min.js');
                await loadScript('https://massarservice.men.gov.ma/moutamadris/ScriptsIntegration/assets/js/plugin/slick.min.js');
                await loadScript('https://massarservice.men.gov.ma/moutamadris/ScriptsIntegration/assets/js/plugin/jquery.mCustomScrollbar.concat.min.js');
                await loadScript('https://massarservice.men.gov.ma/moutamadris/ScriptsIntegration/assets/js/scripts.min.js');
                await loadScript('https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js');
                await loadScript('https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js');
                console.log('Scripts loaded successfully');
            } catch (error) {
                console.error('Error loading scripts:', error);
            }
        };

        loadScripts();
    }, []); // empty dependency array means it will run once when the component mounts
    return(
        <div className="wrapperNav ">
                <span className="overlay"></span>
                <div className="inner">
                    <a className="btnMenu" href="#">
                        <span></span>
                    </a>
                    <nav className="navLinks mainNav">
        <ul id="sidebar-menu">
        <li className="icon icon-home">
            <Link to="/moutamadris/Admin/Dashboard">
                <span>الرئيسية</span>
            </Link>
        </li>
        <li className="icon icon-create hasSubMenu">
            <Link to="/moutamadris/Admin/AeditDepartment">
                <span>تعديل الموارد</span>
            </Link>
            <div className="subMenu">
            <div className="subInner">
                    <h2 className="title">تعديل الموارد</h2>
                    <div className="subNav">
                        <ul>
                            <li>
                                <Link to="/moutamadris/Admin/ASubjectsPage">
                                    <span>تعديل على معاملات المواد</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/moutamadris/Admin/ScoreInputForm">
                                    <span>تعديل نقاط الفروض</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/moutamadris/Admin/AddDepartment">
                                    <span>اظافة الأقسام</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/moutamadris/Admin/AeditDepartment">
                                    <span>تعديل الأقسام</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
        </div>
        </li>
        <li className="icon icon-service">
            <Link to="/moutamadris/Admin/UserForm">
                <span>اضافة تلميذ</span>
            </Link>
        </li>
        <li className="icon icon-resource">
            <Link to="/moutamadris/Admin/ASearchStudent" >
                <span>لإحة الثلاميذ</span>
            </Link>
        </li>
        <li className="icon icon-management">
            <Link to="/moutamadris/Admin/AddSubject">
                <span>اضافة مادة جديد</span>
            </Link>
        </li>
    </ul>
</nav>
</div>
</div>

    );
};

export default Header;