import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../assets/css/Login.css';
import '../../assets/css/errors.css';
import '../../assets/css/settings.css';
import '../../assets/css/global.css';
import '../../assets/css/input.css';
import '../../assets/css/forms.css';
import { API_URL } from '../../context/AuthContext';
import { useAuth } from '../../context/AuthContext';
import Header from '../../Header/AHeader';

const ScoreInputForm = () => {
    const { user } = useAuth();

    const [studentId, setStudentId] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [subjectId, setSubjectId] = useState('');
    const [score1, setScore1] = useState('');
    const [score2, setScore2] = useState('');
    const [score3, setScore3] = useState('');
    const [score4, setScore4] = useState('');
    const [departments, setDepartments] = useState([]);
    const [subjects, setSubjects] = useState([]); // حالة لتخزين المواد
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [studentData, setStudentData] = useState(null);
    
    // تعريف الحقول الجديدة
    const [studentName, setStudentName] = useState('');
    const [searchResults, setSearchResults] = useState([]);

   // حساب الدرجة
const calculateGrade = (score1, score2, score3, score4) => {
    // تحويل الدرجات إلى أرقام وفحص وجود قيمة لكل درجة، وتجاهل الدرجات غير المدخلة
    const scores = [score1, score2, score3, score4]
        .map(score => (score === '' || score === null ? null : Number(score)))
        .filter(score => score !== null); // الاحتفاظ فقط بالدرجات المدخلة

    // إذا لم يتم إدخال أي درجات، نعود بقيمة فارغة أو رسالة محددة
    if (scores.length === 0) return 'N/A';

    // حساب المعدل بناءً على الدرجات المتوفرة فقط
    const total = scores.reduce((acc, curr) => acc + curr, 0);
    const average = total / scores.length;

    // تحديث الشروط لتكون مناسبة لنطاق الدرجات 0 - 20
    if (average >= 18) return 'A';
    if (average >= 15) return 'B';
    if (average >= 12) return 'C';
    if (average >= 10) return 'D';
    return 'F';
};


    // جلب الأقسام من API
    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/auth/get-departments`);
                setDepartments(response.data);
            } catch (err) {
                setError('حدث خطأ أثناء جلب الأقسام.');
            }
        };

        fetchDepartments();
    }, []);

    // جلب المواد من API بناءً على قسم الطالب
    const fetchSubjects = async (departmentId) => {
        try {
            const response = await axios.get(`${API_URL}/api/auth/get-subjects/${departmentId}`);
            setSubjects(response.data); // تخزين المواد في الحالة
        } catch (err) {
            setError('حدث خطأ أثناء جلب المواد.');
        }
    };

    const fetchStudentData = async (id) => {
        try {
            const response = await axios.get(`${API_URL}/api/auth/get-student/${id}`);
            if (response.status === 200) {
                setStudentData(response.data);
                setDepartmentId(response.data.departmentId);
                setScore1(response.data.score1 || '');
                setScore2(response.data.score2 || '');
                setScore3(response.data.score3 || '');
                setScore4(response.data.score4 || '');
                
                // جلب المواد بعد تحميل بيانات الطالب
                fetchSubjects(response.data.departmentId);
            }
        } catch (err) {
            if (err.response && err.response.status === 404) {
                setError('لم يتم العثور على الطالب.');
            } else {
                console.error('Error fetching student data:', err);
                setError('فشل في تحميل بيانات الطالب.');
            }
        }
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        setError('');
        const searchTerm = studentName.trim();
    
        if (!searchTerm) {
            setError('الرجاء إدخال اسم الطالب أو رقم الطالب للبحث');
            return;
        }
    
        try {
            const response = await axios.get(`${API_URL}/api/auth/search-student/${searchTerm}`);
            setSearchResults(response.data);
        } catch (err) {
            setError('حدث خطأ أثناء البحث.');
        }
    };

    // دالة لاختيار الطالب من نتائج البحث
    const handleSelectStudent = (student) => {
        setStudentId(student.student_id); // تعيين ID الطالب المحدد
        setStudentName(`${student.fname_ar} ${student.lname_ar}`); // تعيين اسم الطالب
        fetchStudentData(student.student_id); // جلب بيانات الطالب
        setSearchResults([]); // مسح نتائج البحث بعد الاختيار
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        const score1Num = Number(score1) || 0;
        const score2Num = Number(score2) || 0;
        const score3Num = Number(score3) || 0;
        const score4Num = Number(score4) || 0;

        const calculatedGrade = calculateGrade(score1Num, score2Num, score3Num, score4Num);

        try {
            const response = await axios.put(`${API_URL}/api/auth/update-score/${studentId}`, {
                subject_id: subjectId,
                score1: score1Num,
                score2: score2Num,
                score3: score3Num,
                score4: score4Num,
                grade: calculatedGrade,
            });

            setSuccessMessage('تم تحديث الدرجات بنجاح');
            console.log(response.data);
        } catch (err) {
            if (err.response) {
                console.error('خطأ الاستجابة:', err.response.data);
                setError(`خطأ ${err.response.status}: ${err.response.data.error || 'فشل في تحديث الدرجات'}`);
            } else if (err.request) {
                setError('لم يتم تلقي استجابة من الخادم.');
                console.error('خطأ الطلب:', err.request);
            } else {
                setError('خطأ في إعداد الطلب.');
                console.error('خطأ الرسالة:', err.message);
            }
        }
    };

    return (
        <div className="global">
            <div className="dashboardContent">
                <Header />
                <div className='mainContent'>
                    <div className="welcomeWrapper">
                        <div className="welcome">
                            <h1 className="welcomeUser">
                                <span>مرحبا</span> {user.fname_ar} {user.lname_ar},
                            </h1>
                        </div>
                    </div>
                    <div className="main">
                        <div className="leftSide">
                            <div className="inner">
                                <form id="formUpdateScores" onSubmit={handleSubmit}>
                                    <div className="formLogin">
                                        <div className="formLoginStyle">
                                            {error && <div className="error">{error}</div>}
                                            {successMessage && <div className="success">{successMessage}</div>}

                                            {/* حقل رقم الطالب وزر البحث */}
                                            {!studentData ? (
                                                <>
                                                   {/* حقل البحث عن الطالب */}
                                <div className="searchForm">
                                    <input
                                        type="text"
                                        placeholder="ابحث عن طالب"
                                        value={studentName}
                                        onChange={(e) => setStudentName(e.target.value)}
                                    />
                                    <button type="submit"
                                                    className="btn btn-primary w-100"
                                                onClick={handleSearch}>بحث</button>
                                </div>

                                {/* نتائج البحث */}
                                {searchResults.length > 0 && (
                                    <ul>
                                        {searchResults.map(student => (
                                            <li key={student.id} onClick={() => handleSelectStudent(student)}>
                                                {student.fname_ar} {student.lname_ar} - {student.student_id}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                                </>
                                            ) : (
                                                <>
                                                    {/* اختيار القسم */}
                                                    <div className="item">
                                                        <select
                                                            id="DepartmentId"
                                                            name="DepartmentId"
                                                            required
                                                            value={departmentId}
                                                            onChange={(e) => {
                                                                setDepartmentId(e.target.value);
                                                                fetchSubjects(e.target.value); // جلب المواد عند تغيير القسم
                                                            }}
                                                        >
                                                            <option value="" >اختر القسم</option>
                                                            {departments.map(department => (
                                                                <option key={department.id} value={department.id}>
                                                                    {department.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    {/* اختيار المادة */}
                                                    <div className="item">
                                                        <select
                                                            id="SubjectId"
                                                            name="SubjectId"
                                                            required
                                                            value={subjectId}
                                                            onChange={(e) => setSubjectId(e.target.value)}
                                                        >
                                                            <option value="" disabled>اختر المادة</option>
                                                            {subjects.map(subject => (
                                                                <option key={subject.id} value={subject.id}>
                                                                    {subject.subject_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    <div className="item">
                                                        <input
                                                            id="Score1"
                                                            name="Score1"
                                                            placeholder="النقطة 1"
                                                            type="number"
                                                            value={score1}
                                                            onChange={(e) => setScore1(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="item">
                                                        <input
                                                            id="Score2"
                                                            name="Score2"
                                                            placeholder="النقطة 2"
                                                            type="number"
                                                            value={score2}
                                                            onChange={(e) => setScore2(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="item">
                                                        <input
                                                            id="Score3"
                                                            name="Score3"
                                                            placeholder="النقطة 3"
                                                            type="number"
                                                            value={score3}
                                                            onChange={(e) => setScore3(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="item">
                                                        <input
                                                            id="Score4"
                                                            name="Score4"
                                                            placeholder="النقطة 4"
                                                            type="number"
                                                            value={score4}
                                                            onChange={(e) => setScore4(e.target.value)}
                                                        />
                                                    </div>

                                                    {/* زر إرسال البيانات */}
                                                    <div className="item">
                                                        <button type="submit">تحديث الدرجات</button>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScoreInputForm;