import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../assets/css/ALogin.css';
import '../../assets/css/errors.css';
import '../../assets/css/settings.css';
import '../../assets/css/global.css';
import '../../assets/css/input.css';
import '../../assets/css/forms.css';

import Header from '../../Header/AHeader';
import { useAuth } from '../../context/AuthContext';
import { API_URL } from '../../context/AuthContext';

const UserForm = () => {
    const {user} = useAuth();
    const [fname_ar, setFnameAr] = useState('');
    const [lname_ar, setLnameAr] = useState('');
    const [fname_fr, setFnameFr] = useState(''); // Added French first name state
    const [lname_fr, setLnameFr] = useState(''); // Added French last name state
    const [student_id, setStudentId] = useState('');
    const [niveau_scolaire, setNiveauScolaire] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); // State for success message
    const [department, setDepartment] = useState('');
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/auth/get-departments`);
                setDepartments(response.data);
            } catch (err) {
                setError('حدث خطأ أثناء جلب الأقسام.');
            }
        };

        fetchDepartments();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await axios.post(`${API_URL}/api/auth/register`, {
                fname_ar,
                lname_ar,
                fname_fr, // Include French first name
                lname_fr, // Include French last name
                student_id,
                niveau_scolaire,
                password,
                department // Include department
            });

            // Handle the response as needed
            console.log(response.data);
            // Redirect or show a success message here

        } catch (err) {
            // Update the error message for better feedback
            if (err.response) {
                // The request was made and the server responded with a status code
                setError(`خطأ ${err.response.status}: ${err.response.data}`);
                console.error('Error response:', err.response);
            } else if (err.request) {
                // The request was made but no response was received
                setError('لم يتم تلقي استجابة من الخادم.');
                console.error('Error request:', err.request);
            } else {
                // An error occurred in setting up the request
                setError('حدث خطأ أثناء إعداد الطلب.');
                console.error('Error message:', err.message);
            }
        }
    };

    return (
        <div className="global">
        <div className="dashboardContent">
          <Header />
<div className='mainContent'>
<div className="welcomeWrapper">
    <div className="welcome">
        <h1 className="welcomeUser"><span>مرحبا</span>  {user.fname_ar} {user.lname_ar},</h1>
    </div>
</div>

<div className="bannerWrapper"></div>

<div className="modals" id="loadingDiv"></div>
<div className="formStyle"></div>
            <div className="main">
                <div className="leftSide">
                    <div className="inner">
                        <form id="formUser" onSubmit={handleSubmit}>
                            <div className="formLogin">
                                <div className="formLoginStyle">
                                    {error && <div className="error">{error}</div>}
                                    {successMessage && <div className="success">{successMessage}</div>}
                                    <div className="item">
                                        <input
                                            id="FnameAr"
                                            name="FnameAr"
                                            placeholder="الاسم الأول بالعربية"
                                            required
                                            type="text"
                                            value={fname_ar}
                                            onChange={(e) => setFnameAr(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <input
                                            id="LnameAr"
                                            name="LnameAr"
                                            placeholder="اللقب بالعربية"
                                            required
                                            type="text"
                                            value={lname_ar}
                                            onChange={(e) => setLnameAr(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <input
                                            id="FnameFr"
                                            name="FnameFr"
                                            placeholder="First Name in French"
                                            required
                                            type="text"
                                            value={fname_fr}
                                            onChange={(e) => setFnameFr(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <input
                                            id="LnameFr"
                                            name="LnameFr"
                                            placeholder="Last Name in French"
                                            required
                                            type="text"
                                            value={lname_fr}
                                            onChange={(e) => setLnameFr(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <input
                                            id="StudentId"
                                            name="StudentId"
                                            placeholder="رقم الطالب"
                                            required
                                            type="text"
                                            value={student_id}
                                            onChange={(e) => setStudentId(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <select
                                            id="Department"
                                            name="Department"
                                            required
                                            value={department}
                                            onChange={(e) => setDepartment(e.target.value)}
                                        >
                                            <option value="">اختر القسم</option>
                                            {departments.map((dept) => (
                                                <option key={dept.id} value={dept.id}>
                                                    {dept.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="item">
                                        <input
                                            id="Password"
                                            name="Password"
                                            placeholder="كلمة المرور"
                                            required
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <button id="btnSubmit" className="submitBtn" type="submit">
                                            تسجيل
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
            </div>
            </div>
            
            </div>
    );
};

export default UserForm;