// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    ar: {
        translation: {
            "welcome": "مرحبا",
            "language": "اللغة"
        }
    },
    fr: {
        translation: {
            "welcome": "Bienvenue",
            "language": "Langue"
        }
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'ar', // اللغة الافتراضية
    fallbackLng: 'ar',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;