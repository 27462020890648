import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Dashboard from './components/pages/Dashboard/Dashboard';
import GetNotesEleve from './components/pages/Dashboard/GetNotesEleve';
import AddSubject from './components/pages/Auth/AddSubject'
import Login from './components/pages/Auth/Login';
import AddDepartment from './components/pages/Auth/AddDepartment'
import StudentProfile from './components/pages/StudentProfile';
import NotFound from './components/pages/error/NotFound';
import PrivateRoute from './components/routes/PrivateRoute';
import UserForm from './components/pages/Auth/UserForm'; // استيراد UserForm
import { AuthProvider } from './components/context/AuthContext'; // استيراد AuthProvider
import UpdateStudentForm from './components/pages/Auth/UpdateStudentForm'
import { LanguageProvider } from './components/context/LanguageContext';
import ScoreInputForm from './components/pages/Auth/ScoreInputForm';
import ASearchStudent from './components/pages/Auth/ASearchStudent';
import ADashboard from './components/pages/Dashboard/ADashboard';
import ASubjectsPage from './components/pages/Dashboard/ASubjectsPage';
import AeditDepartment from './components/pages/Dashboard/AeditDepartment';
import './i18n'; // تحميل إعداد التدويل

const App = () => {
    return (
        <AuthProvider>
            <LanguageProvider>
            <Router>
                <Routes>
                    <Route path="/moutamadris/Account" element={<Login />} />
                    <Route path="/moutamadris/Dashboard" element={<PrivateRoute component={Dashboard} />} />
                    <Route path="/moutamadris/TuteurEleves/GetNotesEleve" element={<PrivateRoute component={GetNotesEleve} />} />
                    <Route path="/moutamadris/Admin/AddDepartment" element={<PrivateRoute component={AddDepartment} />} />
                    <Route path="/moutamadris/Admin/AddSubject" element={<PrivateRoute component={AddSubject} />} />
                    <Route path="/moutamadris/UpdateStudentForm" element={<PrivateRoute component={UpdateStudentForm} />} />
                    <Route path="/profile" element={<PrivateRoute component={StudentProfile} />} />
                    <Route path="/moutamadris/Admin/ScoreInputForm" element={<PrivateRoute component={ScoreInputForm} />} />
                    <Route path="/moutamadris/Admin/UserForm" element={<UserForm />} />
                    <Route path="/moutamadris/Admin/ASearchStudent" element={<ASearchStudent />} />
                    <Route path="/moutamadris/Admin/ASubjectsPage" element={<ASubjectsPage />} />
                    <Route path="/moutamadris/Admin/AeditDepartment" element={<AeditDepartment />} />
                    {/* Admin Area */}
                    <Route path="/moutamadris/Admin/Dashboard" element={<PrivateRoute component={ADashboard} />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
            </LanguageProvider>
        </AuthProvider>
    );
};

export default App;