import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import Header from '../../Header/AHeader';
import '../../assets/css/Dashboard.css';
import '../../assets/css/Login.css';
import '../../assets/css/errors.css';
import '../../assets/css/settings.css';
import '../../assets/css/input.css';
import '../../assets/css/forms.css';
import { API_URL } from '../../context/AuthContext';

const AeditDepartment = () => {
    const { user } = useAuth();
    const [departments, setDepartments] = useState([]);
    const [coefficients, setCoefficients] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true); // حالة التحميل

    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await fetch(`${API_URL}/api/auth/get-departments`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log('Fetched departments:', data);
                setDepartments(Array.isArray(data) ? data : []);
            } catch (error) {
                console.error('Error fetching departments:', error);
                setErrorMessage('حدث خطأ أثناء جلب الأقسام.');
                setDepartments([]);
            } finally {
                setLoading(false); // تعيين حالة التحميل إلى false بغض النظر عن النجاح أو الفشل
            }
        };

        fetchDepartments();
    }, []);

    const handleCoefficientChange = (id, value) => {
        setCoefficients({ ...coefficients, [id]: value });
    };

    const handleSaveCoefficients = async () => {
        const departmentsWithCoefficients = departments.map(department => ({
            id: department.id,
            coefficient: parseFloat(coefficients[department.id]) || 0 // استخدم 0 إذا لم يتم إدخال قيمة
        }));

        try {
            const response = await fetch(`${API_URL}/api/auth/departments/coefficient`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ departments: departmentsWithCoefficients }),
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            alert('تم تحديث المعاملات بنجاح.');
            setErrorMessage(''); // مسح رسالة الخطأ عند النجاح
        } catch (error) {
            console.error('Error saving coefficients:', error);
            alert('حدث خطأ أثناء تحديث المعاملات.');
            setErrorMessage('حدث خطأ أثناء تحديث المعاملات.'); // تخزين رسالة الخطأ
        }
    };

    const handleDeleteDepartment = async (departmentId) => {
        try {
            const response = await fetch(`${API_URL}/api/auth/departments/${departmentId}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            // قم بتحديث الحالة لإزالة القسم المحذوف من العرض
            setDepartments(prevDepartments => prevDepartments.filter(department => department.id !== departmentId));
            alert('تم حذف القسم بنجاح.');
        } catch (error) {
            console.error('Error deleting department:', error);
            alert('حدث خطأ أثناء حذف القسم.');
        }
    };

    // التحقق من صحة المعاملات
    const areCoefficientsValid = departments.every(department => 
        !isNaN(parseFloat(coefficients[department.id])) // التأكد من أن كل معامل هو رقم صالح
    );

    return (
        <div>
            <div className="global">
                <div className="dashboardContent">
                    <Header />
                    <div className='mainContent'>
                        <div className="welcomeWrapper">
                            <div className="welcome">
                                <h1 className="welcomeUser">
                                    <span>مرحبا</span> {user.fname_ar} {user.lname_ar},
                                </h1>
                            </div>
                        </div>
                        <div className="formStyle">
                            <div className="widgetsContainer">
                                <div className="widget">
                                    <div className="widgetHeader light">
                                        <h2 className="title">إدارة الأقسام</h2>
                                    </div>
                                    <div className="widgetCont">
                                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                                        {loading ? (
                                            <p>Loading departments...</p> // رسالة التحميل
                                        ) : (
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>اسم القسم</th>
                                                                <th>الإجراءات</th> {/* عمود الإجراءات */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {departments.length > 0 ? (
                                                                departments.map(department => (
                                                                    <tr key={department.id}>
                                                                        <td>{department.name}</td>

                                                                        <td>
                                                                            <button
                                                                                className="btn btn-danger"
                                                                                onClick={() => handleDeleteDepartment(department.id)}
                                                                            >
                                                                                حذف
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="3">لا توجد أقسام متاحة.</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary w-100"
                                                        onClick={handleSaveCoefficients}
                                                        disabled={!areCoefficientsValid} // تعطيل الزر إذا كانت أي معامل غير صالحة
                                                    >
                                                        حفظ المعاملات
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AeditDepartment;