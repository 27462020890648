import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Header/Header';
import { useAuth } from '../../context/AuthContext';
// import '../../assets/css/Dashboard.css'; // تأكد من وجود ملف CSS لتنسيق المكون
import { API_URL } from '../../context/AuthContext';

import '../../assets/css/Dashboard.css';
import '../../assets/css/Login.css';
import '../../assets/css/errors.css';
import '../../assets/css/settings.css';
import '../../assets/css/input.css';
import '../../assets/css/forms.css';
const Dashboard = () => {
    const {user} = useAuth();
    return (
        <div>
    <div className="global">
        <div className="dashboardContent greenNav">
          <Header />
<div className='mainContent'>
<div className="welcomeWrapper">
    <div className="welcome">
        <h1 className="welcomeUser"><span>مرحبا</span>  {user.fname_ar} {user.lname_ar},</h1>
    </div>
</div>

<div className="bannerWrapper"></div>

<div className="modals" id="loadingDiv"></div>
<div className="formStyle"></div>
<div className="widgetsContainer">

<div className="col-md-12">



    <div className=" col-md-4 ">
    <div className="newRess cours AnimateBlock" style={{ cursor: 'pointer', margin: 'auto' }} id="SecuriteRoutiereUrl">
    <div className="outer">
                <div className="inner">
                    <h2 className="title ">
                        <span className="nbr" data-number="8"></span>
                        أستعد لإختبار السلامة الطرقية
                    </h2>
                </div>
            </div>
        </div>
    </div>



</div>

<div className="col-md-12" style={{margin: '10px'}}></div>

<div className="widget col2" id="DevoirDiv">
    
<div className="widgetHeader">
<h2 className="title">عمل للإنجاز <span>(0)</span></h2>
<div className="actions">
    <a href="https://massarservice.men.gov.ma/moutamadris/Dashboard#" id="refreshDevoir" className="refresh">
        <span className="tooltip">26-10-2024 | 5:16</span>
    </a>
    
</div>
</div>
<div className="widgetCont">
<div className="tableWrapper">
    <table>
        <tbody>
        </tbody>
    </table>
</div>
</div>
<div className="widgetNav">

<div className="paginate">
    <ul>
        <li className="prev" id="prev"><a href="https://massarservice.men.gov.ma/moutamadris/Dashboard#"></a></li>

        <li className="liDay " id="day_1"><a href="https://massarservice.men.gov.ma/moutamadris/Dashboard#" data-page="Lun">Lun</a></li>

        <li className="liDay " id="day_2"><a href="https://massarservice.men.gov.ma/moutamadris/Dashboard#" data-page="Mar">Mar</a></li>

        <li className="liDay " id="day_3"><a href="https://massarservice.men.gov.ma/moutamadris/Dashboard#" data-page="Mer">Mer</a></li>

        <li className="liDay " id="day_4"><a href="https://massarservice.men.gov.ma/moutamadris/Dashboard#" data-page="Jeu">Jeu</a></li>

        <li className="liDay " id="day_5"><a href="https://massarservice.men.gov.ma/moutamadris/Dashboard#" data-page="Ven">Ven</a></li>

        <li className="liDay current selectedOne" id="day_6"><a href="https://massarservice.men.gov.ma/moutamadris/Dashboard#" data-page="Sam">Sam</a></li>

        <li className="next" id="next"><a href="https://massarservice.men.gov.ma/moutamadris/Dashboard#"></a></li>
    </ul>
</div>
</div>
</div>


<div className="widget col1" id="ControleDiv">
    
<div className="widgetHeader">
    <h2 className="title">التقويم والامتحانات <span>(0)</span></h2>
    <div className="actions">
        <a href="https://massarservice.men.gov.ma/moutamadris/Dashboard#" id="refreshControle" className="refresh">
            <span className="tooltip">26-10-2024 | 5:16</span>
        </a>
        <a href="https://massarservice.men.gov.ma/moutamadris/TuteurEleves/GetCalendierControls" className="scale" title="Agrandir"></a>
    </div>
</div>
<div className="widgetCont">
    <div className="tableWrapper">
        <table>
            <tbody>
            </tbody>
        </table>
    </div>
</div>
</div>




<div className="widget half" id="AbsenceDiv">
    
<div className="widgetHeader">
    <h2 className="title">الغياب <span>(0)</span></h2>
    <div className="actions">
        <a href="https://massarservice.men.gov.ma/moutamadris/Dashboard#" id="AbsencesRefresh" className="refresh">
            <span className="tooltip">26-10-2024 | 5:16</span>
        </a>
        <a href="https://massarservice.men.gov.ma/moutamadris/TuteurEleves/GetAbsence" className="scale" title="Agrandir"></a>
    </div>
</div>
<div className="widgetCont">
    <div className="tableWrapper">
        <table>
            <tbody>
            </tbody>
        </table>
    </div>
</div>

</div>

<div className="widget half" id="NoteDiv">
    

<div className="widgetHeader">
    <h2 className="title">آخر النقط <span>(0)</span></h2>
    <div className="actions">
        <a href="https://massarservice.men.gov.ma/moutamadris/Dashboard#" id="refreshNote" className="refresh">
            <span className="tooltip">26-10-2024 | 5:16</span>
        </a>
        <a href="https://massarservice.men.gov.ma/moutamadris/TuteurEleves/GetNotesEleve" className="scale" title="Agrandir"></a>
    </div>
</div>
<div className="widgetCont">
    <div className="tableWrapper">
        <table>
            <tbody>
            </tbody>
        </table>
    </div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

    );
};

export default Dashboard;
