import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../assets/css/Login.css';
import '../../assets/css/errors.css';
import '../../assets/css/settings.css';
import '../../assets/css/global.css';
import '../../assets/css/input.css';
import '../../assets/css/forms.css';
import { API_URL } from '../../context/AuthContext';

const UpdateStudentForm = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [students, setStudents] = useState([]);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // State for student info
    const [fname_ar, setFnameAr] = useState('');
    const [lname_ar, setLnameAr] = useState('');
    const [fname_fr, setFnameFr] = useState('');
    const [lname_fr, setLnameFr] = useState('');
    const [student_id, setStudentId] = useState('');
    const [niveau_scolaire, setNiveauScolaire] = useState('');
    const [password, setPassword] = useState('');
    const [department, setDepartment] = useState('');
    const [school_name, setSchoolName] = useState('');
    const [status, setStatus] = useState(''); // إضافة حالة الطالب
    const [departments, setDepartments] = useState([]);

    // جلب الأقسام عند تحميل المكون
    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/auth/get-departments`);
                setDepartments(response.data);
            } catch (err) {
                setError('حدث خطأ أثناء جلب الأقسام.');
            }
        };

        fetchDepartments();
    }, []);

    const handleSearch = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await axios.get(`${API_URL}/api/auth/search-student/${searchTerm}`);
            setStudents(response.data);
        } catch (err) {
            setError('حدث خطأ أثناء البحث.');
        }
    };

    const handleStudentSelect = (student) => {
        setFnameAr(student.fname_ar);
        setLnameAr(student.lname_ar);
        setFnameFr(student.fname_fr);
        setLnameFr(student.lname_fr);
        setStudentId(student.student_id);
        setNiveauScolaire(student.niveau_scolaire);
        setPassword(student.password);
        setDepartment(student.department_id);
        setSchoolName(student.school_name);
        setStatus(student.status); // تعيين حالة الطالب
        setStudents([]);
    };

   const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    try {
        const response = await axios.put(`${API_URL}/api/auth/update-student/${student_id}`, {
            fname_ar,
            lname_ar,
            fname_fr,
            lname_fr,
            niveau_scolaire,
            password,
            department,  // تأكد من تمرير department هنا
            school_name,
            status,
        });

        setSuccessMessage(response.data.message);
    } catch (err) {
        if (err.response) {
            setError(`خطأ ${err.response.status}: ${err.response.data.message}`);
        } else {
            setError('حدث خطأ أثناء إعداد الطلب.');
        }
    }
};


    return (
        <div className="loginContent">
            <div className="main">
                <div className="rightSide">
                    <div className="welcome">
                        <h1 className="welcomeTitle">تحديث معلومات الطالب</h1>
                        <p className="welcomeDesc">يرجى ملء المعلومات أدناه للتحديث</p>
                    </div>
                </div>
                <div className="leftSide">
                    <div className="inner">
                        <form id="formUser" onSubmit={handleSearch}>
                            <div className="formLogin">
                                <div className="formLoginStyle">
                                    {error && <div className="error">{error}</div>}
                                    {successMessage && <div className="success">{successMessage}</div>}
                                    <div className="item">
                                        <input
                                            placeholder="ابحث عن اسم أو رقم الطالب"
                                            required
                                            type="text"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <button type="submit" className="submitBtn">بحث</button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        {students.length > 0 && (
                            <div className="searchResults">
                                <h2>نتائج البحث:</h2>
                                <ul>
                                    {students.map((student) => (
                                        <li key={student.student_id} onClick={() => handleStudentSelect(student)}>
                                            {student.fname_ar} {student.lname_ar} ({student.student_id})
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <form id="formUser" onSubmit={handleSubmit}>
                            <div className="formLogin">
                                <div className="formLoginStyle">
                                    <div className="item">
                                        <input
                                            placeholder="الاسم الأول بالعربية"
                                            required
                                            type="text"
                                            value={fname_ar}
                                            onChange={(e) => setFnameAr(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <input
                                            placeholder="اللقب بالعربية"
                                            required
                                            type="text"
                                            value={lname_ar}
                                            onChange={(e) => setLnameAr(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <input
                                            placeholder="Prénom (بالفرنسية)"
                                            required
                                            type="text"
                                            value={fname_fr}
                                            onChange={(e) => setFnameFr(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <input
                                            placeholder="Nom (بالفرنسية)"
                                            required
                                            type="text"
                                            value={lname_fr}
                                            onChange={(e) => setLnameFr(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <input
                                            placeholder="رقم الطالب"
                                            required
                                            type="text"
                                            value={student_id}
                                            onChange={(e) => setStudentId(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <select
                                            required
                                            value={department}
                                            onChange={(e) => setDepartment(e.target.value)}
                                        >
                                            <option value="">اختر القسم</option>
                                            {departments.map((dept) => (
                                                <option key={dept.id} value={dept.id}>
                                                    {dept.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="item">
                                        <input
                                            placeholder="اسم المدرسة"
                                            required
                                            type="text"
                                            value={school_name}
                                            onChange={(e) => setSchoolName(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <input
                                            placeholder="كلمة المرور"
                                            required
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <input
                                            placeholder="حالة الطالب"
                                            required
                                            type="text"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)} // تغيير حالة الطالب
                                        />
                                    </div>
                                    <div className="item">
                                        <button className="submitBtn" type="submit">
                                            تحديث
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateStudentForm;