import React, { createContext, useState, useContext, useEffect } from 'react';


export const API_URL = "https://api.threadles.net/backend";
// إنشاء سياق
const AuthContext = createContext();

// توفير السياق
export const AuthProvider = ({ children }) => {
    // ضبط الحالة الأولية بناءً على ما إذا كان هناك مستخدم في localStorage
    const initialUser = JSON.parse(localStorage.getItem('user'));
    const [user, setUser] = useState(initialUser);
    const [isAuthenticated, setIsAuthenticated] = useState(!!initialUser);

    // استعادة حالة المستخدم من localStorage عند تحميل التطبيق
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
            setIsAuthenticated(true); // تأكد من أن المستخدم مصادق عليه
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    const login = (userData) => {
        setUser(userData.user); // تأكد من أن userData.user هو المستخدم الصحيح
        setIsAuthenticated(true);
        
        // تخزين بيانات المستخدم
        localStorage.setItem('user', JSON.stringify(userData.user)); // تخزين بيانات المستخدم
        localStorage.setItem('token', userData.token); // تخزين الرمز المميز
    
        // تخزين student_id
        if (userData.user && userData.user.student_id) {
            localStorage.setItem('student_id', userData.user.student_id); // تخزين student_id
            console.log('Student ID stored:', userData.user.student_id); // تأكيد التخزين
        }
    };
    
    
    

    // دالة تسجيل الخروج
    const logout = () => {
        setUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem('user'); // إزالة المعلومات من localStorage
    };

    return (
        <AuthContext.Provider value={{ user, isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// دالة للوصول إلى سياق المستخدم
export const useAuth = () => {
    return useContext(AuthContext);
};