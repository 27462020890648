import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import Header from '../../Header/Header';
import '../../assets/css/Dashboard.css';
import '../../assets/css/Login.css';
import '../../assets/css/errors.css';
import '../../assets/css/settings.css';
import '../../assets/css/input.css';
import '../../assets/css/forms.css';
import { API_URL } from '../../context/AuthContext';

const GetNotesEleve = () => {
    const { user } = useAuth();
    const [selectedYear, setSelectedYear] = useState('2024');
    const [selectedSession, setSelectedSession] = useState('0');
    const [resultBulletin, setResultBulletin] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [studentsCount, setStudentsCount] = useState(0);
    const [activeTab, setActiveTab] = useState('tab_cc');
    const [subjects, setSubjects] = useState([]);
    const [overallAverage, setOverallAverage] = useState('N/A');
    const [loading, setLoading] = useState(false); // حالة التحميل

    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await fetch(`${API_URL}/api/auth/get-departments`);
                const data = await response.json();
                setDepartments(data);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        fetchDepartments();
    }, []);

    useEffect(() => {
        const fetchStudentsCount = async () => {
            try {
                const response = await fetch(`${API_URL}/api/auth/check-department-capacity/${user.department_id}`);
                const data = await response.json();
                setStudentsCount(data.count);
            } catch (error) {
                console.error('Error fetching students count:', error);
            }
        };

        fetchStudentsCount();
    }, [user.department_id]);

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await fetch(`${API_URL}/api/auth/subjects`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setSubjects(data);
            } catch (error) {
                console.error('Error fetching subjects:', error);
            }
        };

        fetchSubjects();
    }, []);

    const handleSearchNotes = async () => {
        const student_id = localStorage.getItem('student_id');
        const annee = selectedYear;
        const idSession = selectedSession;

        if (!student_id) {
            console.error('Student ID not found.');
            return;
        }

        setLoading(true); // بدء التحميل

        try {
            const response = await fetch(`${API_URL}/api/auth/GetBulletins`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Annee: annee, IdSession: idSession, student_id }),
            });

            if (!response.ok) throw new Error(`Network error: ${response.statusText}`);
            const data = await response.json();
            setResultBulletin(data);

            const overall = calculateOverallAverage(data, subjects);
            setOverallAverage(overall);

            // الانتظار لمدة 5 ثوانٍ قبل إيقاف التحميل
            setTimeout(() => {
                setLoading(false); // إيقاف التحميل بعد 5 ثوانٍ
            }, 5000);
        } catch (error) {
            console.error('Error fetching bulletins:', error);
            setLoading(false); // إيقاف التحميل في حالة حدوث خطأ
        }
    };

    const getDepartmentName = (id) => {
        const department = departments.find(dept => dept.id === id);
        return department ? department.name : 'غير محدد';
    };

    const calculateContinuousAssessment = (scores) => {
        const validScores = scores.filter(score => score !== null);
        const total = validScores.reduce((sum, score) => sum + score, 0);
        return validScores.length ? (total / validScores.length).toFixed(2) : 'N/A';
    };

    const calculateOverallAverage = (results, subjects) => {
        let totalWeightedScore = 0;
        let totalWeights = 0;

        results.forEach(result => {
            const subjectObj = subjects.find(subj => subj.subject_name === result.subject);
            const coefficient = subjectObj ? parseFloat(subjectObj.coefficient) : 0;

            const continuousAssessmentScore = parseFloat(calculateContinuousAssessment([result.score1, result.score2, result.score3, result.score4])) || 0;

            if (coefficient > 0) {
                totalWeightedScore += continuousAssessmentScore * coefficient;
                totalWeights += coefficient;
            } else {
                console.warn(`Coefficient not found for subject: ${result.subject}`);
            }
        });

        return totalWeights > 0 ? (totalWeightedScore / totalWeights).toFixed(2) : 'N/A';
    };

    return (
        <div>
            <div className="global">
                <div className="dashboardContent greenNav">
                    <Header />
                    <div className='mainContent'>
                        <div className="welcomeWrapper">
                            <div className="welcome">
                                <h1 className="welcomeUser">
                                    <span>مرحبا</span> {user.fname_ar} {user.lname_ar},
                                </h1>
                            </div>
                        </div>
                        <div className="formStyle">
                            <div className="widgetsContainer">
                                <div className="widget">
                                    <div className="widgetHeader light">
                                        <h2 className="title">تتبع النقط</h2>
                                    </div>
                                    <div className="widgetCont">
                                        <div className="formStyle">
                                            <div className="col-md-12">
                                                <div className="form-group col-md-3">
                                                    <label>السنة الدراسية</label>
                                                    <div className="selectbox">
                                                        <select className="form-control" value={selectedYear} onChange={e => setSelectedYear(e.target.value)}>
                                                            <option value="2024-2023">2024 - 2023</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>الفصل الدراسي</label>
                                                    <div className="selectbox">
                                                        <select className="form-control" value={selectedSession} onChange={e => setSelectedSession(e.target.value)}>
                                                            <option value="0" selected></option>
                                                            <option value="1">الترم الأول</option>
                                                            <option value="2">الترم الثاني</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label className="label">.</label>
                                                    <button type="button" className="btn btn-primary w-100" onClick={handleSearchNotes}>
                                                        البحث
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`widget bayane ${loading ? 'hidden' : ''}`}>
                                        <div className="widgetHeader light">
                                            <h2 className="title">بيان النتائج الدراسية</h2>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <dl>
                                                    <dt className="col-md-2">المؤسسة</dt>
                                                    <dd className="col-md-4">{user.school_name}</dd>
                                                    <dt className="col-md-2">المستوى</dt>
                                                    <dd className="col-md-4">{user.niveau_scolaire}</dd>
                                                    <dt className="col-md-2">القسم</dt>
                                                    <dd className="col-md-4">{getDepartmentName(user.department_id)}</dd>
                                                    <dt className="col-md-2">عدد الثلاميذ</dt>
                                                    <dd className="col-md-4">{studentsCount}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                        <div className='nav-tabs-custom'>
                                            <ul className="nav responsive nav-tabs">
                                                <li className={activeTab === 'tab_cc' ? 'active' : ''} onClick={() => setActiveTab('tab_cc')}>
                                                    <a href="#tab_cc" data-toggle="tab">نقط المراقبة المستمرة</a>
                                                </li>
                                                <li className={activeTab === 'tab_notes_exam' ? 'active' : ''} onClick={() => setActiveTab('tab_notes_exam')}>
                                                    <a href="#tab_notes_exam" data-toggle="tab">بيان النتائج الدراسية</a>
                                                </li>
                                            </ul>
                                            <div className='tab-content responsive'>
                                                {activeTab === 'tab_cc' && (
                                                    <div className="tab-pane active">
                                                        <div className='row'>
                                                            <div className="widgetCont col-md-12">
                                                                {resultBulletin.length > 0 ? (
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>المادة</th>
                                                                                <th>الفرض الأول</th>
                                                                                <th>الفرض الثاني</th>
                                                                                <th>الفرض الثالث</th>
                                                                                <th>الفرض الرابع</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {resultBulletin.map((result, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{result.subject}</td>
                                                                                    <td>{result.score1}</td>
                                                                                    <td>{result.score2}</td>
                                                                                    <td>{result.score3}</td>
                                                                                    <td>{result.score4}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                ) : (
                                                                    <p>الرجاء اختيار فصل دراسي والسنة الدراسية للعرض</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {activeTab === 'tab_notes_exam' && (
                                                    <div className="tab-pane active">
                                                        <div className='row'>
                                                            <div className="widgetCont col-md-12">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>المادة</th>
                                                                            <th>معدل المراقبة المستمرة</th>
                                                                            <th>المعامل</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {resultBulletin.map((result, index) => {
                                                                            const subjectObj = subjects.find(subj => subj.subject_name === result.subject);
                                                                            const coefficient = subjectObj ? parseInt(subjectObj.coefficient) : 'غير متوفر';

                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{result.subject}</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                                <div className="col-md-12" style={{ borderTop: "1px solid #f3f3f3", marginTop: "10px" }}>
                                                                    <label style={{ marginRight: "40%", width: "100px", padding: "3px" }}>معدل الدورة</label>
                                                                    <span style={{ width: "50px" }}></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {activeTab === 'tab_notes_exam1' && (
                                                    <div className="tab-pane active">
                                                        <div className='row'>
                                                            <div className="widgetCont col-md-12">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>المادة</th>
                                                                            <th>معدل المراقبة المستمرة</th>
                                                                            <th>المعامل</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {resultBulletin.map((result, index) => {
                                                                            const subjectObj = subjects.find(subj => subj.subject_name === result.subject);
                                                                            const coefficient = subjectObj ? parseInt(subjectObj.coefficient) : 'غير متوفر';

                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{result.subject}</td>
                                                                                    <td>{calculateContinuousAssessment([result.score1, result.score2, result.score3, result.score4])}</td>
                                                                                    <td>{coefficient}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                                <div className="col-md-12" style={{ borderTop: "1px solid #f3f3f3", marginTop: "10px" }}>
                                                                    <label style={{ marginRight: "40%", width: "100px", padding: "3px" }}>معدل الدورة</label>
                                                                    <span style={{ width: "50px" }}>{overallAverage}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {loading && (
                                        <div className="loading" style={{ textAlign: 'center', marginTop: '20px', background: 'white url(https://massarservice.men.gov.ma/moutamadris/Images/ezgif.gif) no-repeat 50% 50%', opacity: 0.7 }}>
                                            <img src="https://massarservice.men.gov.ma/moutamadris/Images/ezgif.gif" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetNotesEleve;