import React, { useState } from 'react';

import '../../assets/css/Login.css';
import '../../assets/css/errors.css';
import '../../assets/css/settings.css';
import '../../assets/css/global.css';
import '../../assets/css/input.css';
import '../../assets/css/forms.css';
import { API_URL } from '../../context/AuthContext';
import naja7Logo from '../../assets/img/naja7Logo.png';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext'; // استيراد useAuth
import { useNavigate, Navigate } from 'react-router-dom'; // استيراد useNavigate و Navigate
import { useLanguage } from '../../context/LanguageContext';
import { useTranslation } from 'react-i18next';

const Login = () => {
    const { switchLanguage } = useLanguage();
    const { t } = useTranslation();
    const { login, isAuthenticated } = useAuth(); // استخدم login و isAuthenticated من AuthContext
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // استخدام useNavigate

    // إذا كان المستخدم مصدق عليه بالفعل، إعادة التوجيه إلى Dashboard
    if (isAuthenticated) {
        return <Navigate to="/moutamadris/Dashboard" />;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await axios.post(`${API_URL}/api/auth/login`, {
                username,
                password,
            });

            // تسجيل الدخول بنجاح، قم بتحديث حالة المستخدم
            login(response.data); // تأكد من أن response.data تحتوي على بيانات المستخدم
            navigate('/moutamadris/Dashboard'); // إعادة التوجيه باستخدام useNavigate

        } catch (err) {
            setError('اسم المستخدم أو كلمة المرور غير صحيحة');
        }
    };

    return (
        <div className="loginContent">
            <div className="main">
                <div className="rightSide">
                    <div className="welcome">
                        <div className="inner">
                            <h1 className="welcomeTitle">{t('welcome')}</h1>
                            <p className="welcomeDesc">
                                منظومة معلوماتية متكاملة تمكن من إرساء طرق عمل جديدة للتدبير المدرسي على صعيد المؤسسات التعليمية
                            </p>
                        </div>
                    </div>
                </div>
                <div className="leftSide">
                    <div className="inner">
                        <div className="ministryLogo">
                            <img src="https://massarservice.men.gov.ma/moutamadris/ScriptsIntegration/medias/ministry-logo-ar.png" alt="Ministry Logo" />
                        </div>
                        <form id="formLogin" onSubmit={handleSubmit}>
                            <div className="formLogin">
                                <div className="massarLogo">
                                    <img src={naja7Logo} alt="Naja7 Logo" />
                                </div>
                                <div className="formLoginStyle">
                                    <div className="item">
                                        <input
                                            id="UserName"
                                            name="UserName"
                                            placeholder="إسم المستخدم"
                                            required
                                            type="text"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </div>
                                    <div className="item">
                                        <input
                                            id="Password"
                                            name="Password"
                                            placeholder="كلمة المرور"
                                            required
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    {error && <div className="error">{error}</div>} {/* رسالة الخطأ */}
                                    <div className="item">
                                        <button id="btnSubmit" className="submitBtn" type="submit">
                                            تسجيل الدخول
                                        </button>
                                    </div>
                                    <div className="item">
                                        <a href="/moutamadris/MotPassOublieEleve">
                                            نسيت كلمة المرور؟
                                        </a>
                                    </div>
                                    <div className="item">
                                        <a href="/moutamadris/MotPassOublieEleve">
                                            التفعيل الأولي للحساب
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <nav className="navLang">
                            <ul>
                                <li>
                                <a href="#" onClick={() => switchLanguage('ar')}>
                                العربية
                                    </a>
                                </li>
                                <li>
                                <a href="#" onClick={() => switchLanguage('fr')}>
                                الفرنسية
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;